import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FluidImage from "../components/image/fluid-image";
// ? TYPES:
import { Post } from "../models/post.model";

interface Props {
  data: {
    wordpressPost: Post;
    site: {
      id: string;
      siteMetadata: {
        title: string;
        description: string;
      };
    };
  };
}

const PostTemplate = ({ data }: Props) => {
  console.log(data);
  return (
    <Layout>
      <SEO title={data.wordpressPost.title} />
      <article>
        <div style={{ maxWidth: "960px" }}>
          <FluidImage featuredMedia={data.wordpressPost.featured_media} />
        </div>
        <div style={{ display: "inline-block" }}>
          <h1
            dangerouslySetInnerHTML={{ __html: data.wordpressPost.title }}
          ></h1>
          <div
            dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }}
          ></div>
        </div>
      </article>
    </Layout>
  );
};

export const query: any = graphql`
  query PostQuery($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      content
      excerpt
      path
      slug
      status
      sticky
      title
      wordpress_id
      featured_media {
        alt_text
        caption
        date
        id
        media_type
        mime_type
        path
        post
        slug
        source_url
        title
        wordpress_id
        localFile {
          childImageSharp {
            # Try editing the "maxWidth" value to generate resized images.
            fluid(maxWidth: 960, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default PostTemplate;
